<template>
  <div id="app">
    <!-- <mobile msg="" v-if="isMobile == 1" key="mobile" /> -->
    <!-- <pc msg="" v-else-if="isMobile == 2" key="pc" /> -->
	<pc></pc>
  </div>
</template>

<script>
import pc from "./components/pc.vue";
import mobile from "./components/mobile.vue";

export default {
  name: "App",
  components: {
    pc,
    mobile,
  },
  data() {
    return {
      isMobile: 1,
    };
  },
  created() {
    this.checkMobile();
  },
  mounted() {},
  methods: {
    checkMobile() {
      // 获取浏览器首选语言
      var language = navigator.language || navigator.userLanguage;
      // console.log(language);
      // 判断是否为中文（正则方法）
      if (/zh/i.test(language)) {
        // 中文
        this.$i18n.locale = "zh";
        document.title = "天津克莱姆石油设备有限公司";
      } else if (/ar/i.test(language)) {
        // 阿拉伯文
        this.$i18n.locale = "arab";
        document.title = "تقع شركة ZHEJIANG HUA TAI CHEMICALS LIMITED";
      } else {
        // 英文
        this.$i18n.locale = "en";
        document.title = "ZHEJIANG HUA TAI CHEMICALS LIMITED";
      }
      // console.log(this.$i18n.locale);
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (
        userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        this.isMobile = 1;
      } else {
        this.isMobile = 2;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.arabCss {
  direction: rtl;
}
</style>
