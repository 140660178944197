<template>
  <div class="home">
    <!-- 顶部 -->
    <div class="top">
      <div class="flex-between" style="padding: 0.5rem">
        <img
          class="logo"
          alt="logo"
          src="../assets/logo.png"
          @click="go('1')"
        />
        <div>
          <div class="menu-icon" @click="menuChange"></div>
        </div>
      </div>
    </div>
    <template v-if="activeNames">
      <div class="menu-main">
        <div>
          <div class="flex-start" @click="go('1')">
            <div class="line"></div>
            <div class="nav-1">{{ $t("nav1") }}</div>
          </div>
          <div class="flex-start" @click="go('2')">
            <div class="line"></div>
            <div class="nav-1">{{ $t("nav2") }}</div>
          </div>
          <div class="nav-2">
            <div @click="go('2')">{{ $t("nav2-1") }}</div>
            <div @click="go('3')">{{ $t("nav2-2") }}</div>
            <div @click="go('5')">{{ $t("nav2-3") }}</div>
          </div>
          <div class="flex-start" @click="go('4')">
            <div class="line"></div>
            <div class="nav-1">{{ $t("nav3") }}</div>
          </div>
        </div>
        <div class="language">
          <span
            :class="$i18n.locale == 'zh' ? 'cur' : ''"
            @click="anvChange('zh')"
            >中文</span
          >
          |
          <span
            :class="$i18n.locale == 'en' ? 'cur' : ''"
            @click="anvChange('en')"
            >EN</span
          >
          |
          <span
            :class="$i18n.locale == 'arab' ? 'cur' : ''"
            @click="anvChange('arab')"
            >عربي</span
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="navTem == 1">
        <div class="top-mian">
          <el-carousel
            indicator-position="outside"
            :interval="3000"
            ref="slideCarousel"
          >
            <el-carousel-item v-for="item in images" :key="item">
              <img
                class="main-img"
                :src="item"
                alt=""
                style="object-fit: cover"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 中间 -->
        <div class="main">
          <template>
            <div class="main-content-title" style="margin-top: 1rem">
              <span class="main-content-title-one">{{
                $t("nav1-title-1")
              }}</span>
            </div>
            <div class="main-content">
              <p>
                {{ $t("nav1-content-p1") }}
              </p>
              <p>
                {{ $t("nav1-content-p2") }}
              </p>
              <p>
                {{ $t("nav1-content-p3") }}
              </p>
            </div>
            <div class="main-content-title">
              <span class="main-content-title-one">{{
                $t("nav1-title-2")
              }}</span>
            </div>
            <div class="main-content">
              <p>
                {{ $t("nav1-content-p4") }}
              </p>
            </div>
            <div class="main-content-title">
              <span class="main-content-title-one">{{
                $t("nav1-title-3")
              }}</span>
            </div>
            <div class="main-content" style="margin-bottom: 1.5rem">
              <p>
                {{ $t("nav1-content-p5") }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div v-else-if="navTem == 2">
        <div class="main">
          <div
            class="main-content-title"
            style="margin-bottom: 1rem; margin-top: 2rem"
          >
            <span class="main-content-title-one">{{ $t("nav2") }}</span>
          </div>
          <div class="main-content-title-two">
            {{ $t("nav2-1-title") }}
          </div>
          <!-- <el-row :gutter="20">
            <el-col
              :span="12"
              v-for="(o, index) in productList"
              :key="o.name"
              @click.native="dialogInfo(o, index)"
            >
              <el-card
                :body-style="{ padding: '0px' }"
                style="margin-bottom: 20px"
              >
                <img :src="o.img" class="image" />
                <div
                  style="padding: 0.5rem; text-align: center"
                  :class="$i18n.locale == 'zh' ? 'h-3' : 'h-5'"
                >
                  <span class="card-name">{{ o.name }}</span>
                </div>
              </el-card>
            </el-col>
          </el-row> -->
        </div>
      </div>
      <div v-else-if="navTem == 3">
        <!-- <el-image
          :src="imagesMinerals"
          style="width: 100%; height: 12rem; filter: blur(1px)"
        ></el-image> -->
        <div class="kczy-title">
          <p>{{ $t("nav2-2-title") }}</p>
          <p>{{ $t("nav2-2-title2") }}</p>
        </div>
        <!-- <el-row :gutter="20" style="margin: 1rem 0.25rem">
          <el-col :span="12" v-for="(o, index) in kczyList" :key="o.name">
            <el-card
              :body-style="{ padding: '0px' }"
              style="margin-bottom: 20px"
            >
              <img :src="o.img" class="image" />
              <div
                style="
                  padding: 0.5rem;
                  text-align: center;
                  font-size: 1rem;
                  letter-spacing: 3px;
                "
                class="h-3"
              >
                <span class="card-name">{{ o.name }}</span>
              </div>
            </el-card>
          </el-col>
        </el-row> -->
      </div>
      <div v-else-if="navTem == 4">
        <el-image
          :src="imagesCall"
          style="width: 100%; height: 12rem; filter: blur(1px)"
        ></el-image>
        <div class="lxwm">
          <p style="letter-spacing: 3px">{{ $t("nav3-title") }}</p>
          <p class="lxwm-t">
            {{ $t("nav3-title2") }}
          </p>
        </div>
        <div class="main-content lxwm-p">
          <el-row style="margin-bottom: 1.5rem">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">{{ $t("nav3-content-title-1") }}</p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title3") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-address-1")
                  }}</span>
                </p>
                <p>
                  <i class="el-icon-phone-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title4") }}：</span
                  ><span
                    ><a href="tel:0571-63826282" class="lxwm-i-p">{{
                      $t("nav3-content-phone-1")
                    }}</a></span
                  >
                </p>
                <p>
                  <i class="el-icon-message lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title5") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-Email-1")
                  }}</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 1.5rem">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">{{ $t("nav3-content-title-2") }}</p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title3") }}：</span
                  ><span class="lxwm-i-p">{{
                    $t("nav3-content-address-2")
                  }}</span>
                </p>
                <p>
                  <i class="el-icon-phone-outline lxwm-i"></i
                  ><span class="lxwm-s">{{ $t("nav3-title4") }}：</span
                  ><span
                    ><a href="tel:0757-22264360" class="lxwm-i-p">{{
                      $t("nav3-content-phone-2")
                    }}</a></span
                  >
                </p>
              </el-card>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 1.5rem">
            <el-col :span="24">
              <el-card class="box-card">
                <p class="card-title">
                  Hong Kong Hua Tai Chemical Limited（香港华钛化工有限公司）
                </p>
                <p>
                  <i class="el-icon-location-outline lxwm-i"></i
                  ><span class="lxwm-s">Address：</span
                  ><span class="lxwm-i-p"
                    >A27 2/F HING YIP CENTRE, 31 HING YIP STREET KWUN TONG
                    KOWLOON HK</span
                  >
                </p>
                <p>
                  <i class="el-icon-message lxwm-i"></i
                  ><span class="lxwm-s">Email：</span
                  ><span class="lxwm-i-p">3219552358@qq.com</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="navTem == 5">
        <!-- <el-image
          :src="imagesMinerals"
          style="width: 100%; height: 12rem; filter: blur(1px)"
        ></el-image> -->
        <div class="kczy-title">
          <p>{{ $t("nav2-3-title-p1") }}</p>
          <p>{{ $t("nav2-3-title-p2") }}</p>
        </div>
        <div class="main">
          <div class="main-content-title">
            <span class="main-content-title-one">{{
              $t("nav2-3-title1")
            }}</span>
          </div>
          <div class="main-content">
            <p>
              {{ $t("nav2-3-define") }}
            </p>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one">{{
              $t("nav2-3-title2")
            }}</span>
          </div>
          <div class="main-content">
            <p>
              {{ $t("nav2-3-introduction") }}
            </p>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one">{{
              $t("nav2-3-title3")
            }}</span>
          </div>
          <div class="main-content" style="margin-bottom: 1.5rem">
            <p>
              {{ $t("nav2-3-nature") }}
            </p>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one"
              >{{ $t("nav2-3-title4") }}：HTR-223</span
            >
          </div>
          <div>
            <h3 style="text-align: left; margin: 1rem 0">
              {{ $t("nav2-3-title5") }}
            </h3>
            <div>
              <el-table
                class="table"
                :data="tableData2"
                style="width: 100%"
                stripe
              >
                <el-table-column prop="name" :label="$t('drawer-table-title')">
                  <template slot-scope="scope">
                    <div class="flex-between">
                      <div>{{ scope.row.name }}</div>
                      <div style="margin-right: 10px">{{ scope.row.type }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  width="200px"
                  prop="value"
                  :label="$t('nav2-3-title6')"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one">{{ $t("nav2-3-rule") }}</span>
          </div>
          <div class="main-content" style="margin-bottom: 1.5rem">
            <p>
              {{ $t("nav2-3-rule-1-cpntent") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-2-cpntent") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-3-cpntent") }}
            </p>
            <div style="margin-left: 1rem">
              <p>
                {{ $t("nav2-3-rule-3-cpntent-1") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-2") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-3") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-4") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-5") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-6") }}
              </p>
              <p>
                {{ $t("nav2-3-rule-3-cpntent-7") }}
              </p>
            </div>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one">{{
              $t("nav2-3-rule-4")
            }}</span>
          </div>
          <div class="main-content" style="margin-bottom: 1.5rem">
            <p>
              {{ $t("nav2-3-rule-4-cpntent-1") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-4-cpntent-2") }}
            </p>
          </div>
          <div class="main-content-title">
            <span class="main-content-title-one">{{
              $t("nav2-3-rule-5")
            }}</span>
          </div>
          <div class="main-content" style="margin-bottom: 1.5rem">
            <p>
              {{ $t("nav2-3-rule-5-1-content") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-5-2-content") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-5-3-content") }}
            </p>
            <p>
              {{ $t("nav2-3-rule-5-4-content") }}
            </p>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="bottom">
        <div class="w80">
          <p>{{ $t("copyright") }}</p>
        </div>
      </div>
    </template>
    <!-- 置顶 -->
    <div
      class="goTop"
      :class="$i18n.locale == 'arab' ? 'arabGoTop' : ''"
      v-show="top > 60"
      @click="backTop"
    >
      <i class="el-icon-caret-top" style="color: #29b0d7"></i>
    </div>
    <!-- 产品详情弹窗 -->
    <el-drawer
      :title="dTitle"
      :visible.sync="dialogVisible"
      direction="rtl"
      size="100%"
    >
      <div class="drawer-content">
        <div class="dialog-content">
          <p style="color: #29b0d7; font-weight: 700">
            {{ $t("nav2-1-title2") }}：
          </p>
          <p>{{ dContent.describe2 }}</p>
        </div>
        <div class="dialog-content">
          <p style="color: #29b0d7; font-weight: 700">
            {{ $t("drawer-title") }}：
          </p>
          <p>{{ dContent.describe1 }}</p>
        </div>
        <div v-if="tableData && tableData.length > 0">
          <!-- 表格 -->
          <el-table class="table" :data="tableData" style="width: 100%" stripe>
            <el-table-column prop="name" :label="$t('drawer-table-title')">
              <template slot-scope="scope">
                <div class="flex-between">
                  <div>{{ scope.row.name }}</div>
                  <div style="margin-right: 10px">{{ scope.row.type }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="123px"
              prop="value"
              :label="$t('drawer-table-title2')"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "mobile",
  props: {
    msg: String,
  },
  data() {
    return {
      lange: "zh",
      activeNames: false,
      navTem: "1",
      images: [
		// require("../assets/img/banner-00.jpg"),
		// require("../assets/img/banner-01.jpg"),
		// require("../assets/img/banner-02.jpg"),
  //       require("../assets/img/banner-1.jpg"),
  //       require("../assets/img/banner-2.jpg"),
        // require("../assets/img/banner-3.jpg"),
        // require("../assets/img/banner-4.jpg"),
        // require("../assets/img/banner5.jpg"),
      ],
      imagesCall: require("../assets/img/call.jpg"),
      // imagesMinerals: require("../assets/img/minerals.jpg"),
      btnFlag: true,
      top: 0,
      dialogVisible: false,
      dTitle: null,
      dContent: {},
      tableData: [],
    };
  },
  computed: {
    tableName() {
      return {
        name1: this.$t("nav2-1-table-1-name"),
        name2: this.$t("nav2-1-table-2-name"),
        name3: this.$t("nav2-1-table-12-name"),
        name4: this.$t("nav2-1-table-3-name"),
        name5: this.$t("nav2-1-table-4-name"),
        name6: this.$t("nav2-1-table-5-name"),
        name7: this.$t("nav2-1-table-6-name"),
        name8: this.$t("nav2-1-table-7-name"),
        name9: this.$t("nav2-1-table-8-name"),
        name10: this.$t("nav2-1-table-9-name"),
        name11: this.$t("nav2-1-table-10-name"),
        name12: this.$t("nav2-1-table-11-name"),
      };
    },
    // kczyList() {
    //   return [
    //     {
    //       name: this.$t("nav2-2-product-1-name"),
    //       img: require("../assets/tjk.png"),
    //     },
    //     {
    //       name: this.$t("nav2-2-product-2-name"),
    //       img: require("../assets/tk.png"),
    //     },
    //     {
    //       name: this.$t("nav2-2-product-3-name"),
    //       img: require("../assets/lk.png"),
    //     },
    //     {
    //       name: this.$t("nav2-2-product-4-name"),
    //       img: require("../assets/hys.png"),
    //     },
    //     {
    //       name: this.$t("nav2-2-product-5-name"),
    //       img: require("../assets/ltk.png"),
    //     },
    //     {
    //       name: this.$t("nav2-2-product-6-name"),
    //       img: require("../assets/leikuo.png"),
    //     },
    //   ];
    // },
    // productList() {
    //   return [
    //     {
    //       name: this.$t("nav2-1-product-1-name"),
    //       img: require("../assets/gfxtbf.jpg"),
    //       type: "1",
    //       describe1: this.$t("nav2-1-product-1-describe1"),
    //       describe2: this.$t("nav2-1-product-1-describe2"),
    //     },
    //     {
    //       name: this.$t("nav2-1-product-2-name"),
    //       img: require("../assets/tltbf.jpg"),
    //       type: "1",
    //       describe1: this.$t("nav2-1-product-2-describe1"),
    //       describe2: this.$t("nav2-1-product-2-describe2"),
    //     },
    //     {
    //       name: this.$t("nav2-1-product-3-name"),
    //       img: require("../assets/zztbf.jpg"),
    //       type: "1",
    //       describe1: this.$t("nav2-1-product-3-describe1"),
    //       describe2: this.$t("nav2-1-product-3-describe2"),
    //     },
    //     {
    //       name: this.$t("nav2-1-product-4-name"),
    //       img: require("../assets/sltbf.jpg"),
    //       type: "1",
    //       describe1: this.$t("nav2-1-product-4-describe1"),
    //       describe2: this.$t("nav2-1-product-4-describe2"),
    //     },
    //     {
    //       name: this.$t("nav2-1-product-5-name"),
    //       img: require("../assets/smltbf.jpg"),
    //       type: "1",
    //       describe1: this.$t("nav2-1-product-5-describe1"),
    //       describe2: this.$t("nav2-1-product-5-describe2"),
    //     },
    //   ];
    // },
    tableData2() {
      return [
        { name: this.$t("nav2-3-index-1"), value: 99.5, type: "≥" },
        {
          name: this.$t("nav2-3-index-2"),
          value: 12,
          type: " ≤",
        },
        { name: "S,ppm", value: 100, type: " ≤" },
        { name: "Fe,ppm", value: 35, type: " ≤" },
        { name: "P2O5,%", value: 0.05, type: "≤" },
        { name: "D50,um", value: 1.2, type: "≤" },
        { name: "Na,ppm", value: 50, type: "≤" },
        {
          name: "K,ppm",
          value: "50",
          type: "≤",
        },
        { name: this.$t("nav2-3-index-3"), value: 0.5, type: "≤" },
        { name: this.$t("nav2-3-index-4"), value: "40-80", type: " " },
        { name: this.$t("nav2-3-index-5"), value: 0.1, type: "≤" },
      ];
    },
  },
  mounted() {
    this.slideBanner();
    window.addEventListener("scroll", this.windowScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    anvChange(type) {
      if (type == "zh") {
        this.$i18n.locale = "zh";
        document.title = "浙江华钛化工有限公司";
      } else if (type == "en") {
        this.$i18n.locale = "en";
        document.title = "ZHEJIANG HUA TAI CHEMICALS LIMITED";
      } else {
        // 阿拉伯文
        this.$i18n.locale = "arab";
        document.title = "تقع شركة ZHEJIANG HUA TAI CHEMICALS LIMITED";
      }
    },
    menuChange() {
      this.activeNames = this.activeNames ? false : true;
    },
    go(type) {
      this.navTem = type;
      this.activeNames = false;
    },
    changeLange() {
      this.$i18n.locale = this.lange;
    },
    windowScroll() {
      this.top = window.scrollY;
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
    dialogInfo(o, index) {
      // console.log(o);
      this.dTitle = o.name;
      this.dContent.describe1 = o.describe1;
      this.dContent.describe2 = o.describe2;
      switch (index) {
        case 0:
          this.tableData = [
            { name: this.tableName.name1, value: 92, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 19.5, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 97.0, type: "≥" },
          ];
          break;
        case 1:
          this.tableData = [
            { name: this.tableName.name1, value: 92, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 22, type: "≤" },
            { name: this.tableName.name11, value: 60, type: "≥" },
            { name: this.tableName.name12, value: 97.5, type: "≥" },
          ];
          break;
        case 2:
          this.tableData = [
            { name: this.tableName.name1, value: 90, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.0 - 9.0",
              type: "",
            },
            { name: this.tableName.name10, value: 25, type: "≤" },
            { name: this.tableName.name11, value: 10, type: "≥" },
            { name: this.tableName.name12, value: 97.5, type: "≥" },
          ];
          break;
        case 3:
          this.tableData = [
            { name: this.tableName.name1, value: 93, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 97.5, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.5 - 8.5",
              type: "",
            },
            { name: this.tableName.name10, value: 20, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 98, type: "≥" },
          ];
          break;
        case 4:
          this.tableData = [
            { name: this.tableName.name1, value: 96.5, type: "≥" },
            {
              name: this.tableName.name2,
              value: this.tableName.name3,
              type: "",
            },
            { name: this.tableName.name4, value: 0.5, type: " ≤" },
            { name: this.tableName.name5, value: 0.5, type: " ≤" },
            { name: this.tableName.name6, value: 0.02, type: "≤" },
            { name: this.tableName.name7, value: 98, type: "≥" },
            { name: this.tableName.name8, value: 100, type: "≥" },
            {
              name: this.tableName.name9,
              value: "6.5 - 8.5",
              type: "",
            },
            { name: this.tableName.name10, value: 18, type: "≤" },
            { name: this.tableName.name11, value: 80, type: "≥" },
            { name: this.tableName.name12, value: 97, type: "≥" },
          ];
          break;
        // case 5:
        //   this.tableData = [
        //     { name: this.tableName.name1, value: 96.5, type: "≥" },
        //     {
        //       name: this.tableName.name2,
        //       value: this.tableName.name3,
        //       type: "",
        //     },
        //     { name: this.tableName.name4, value: 0.5, type: " ≤" },
        //     { name: this.tableName.name5, value: 0.5, type: " ≤" },
        //     { name: this.tableName.name6, value: 0.02, type: "≤" },
        //     { name: this.tableName.name7, value: 98, type: "≥" },
        //     { name: this.tableName.name8, value: 100, type: "≥" },
        //     {
        //       name: this.tableName.name9,
        //       value: "6.0 - 8.5",
        //       type: "",
        //     },
        //     { name: this.tableName.name10, value: 18, type: "≤" },
        //     { name: this.tableName.name11, value: 80, type: "≥" },
        //     { name: this.tableName.name12, value: 97, type: "≥" },
        //   ];
        //   break;

        default:
          break;
      }
      this.dialogVisible = true;
    },
    slideBanner() {
      var box = document.querySelector(".el-carousel__container");
      var startPointX = 0;
      var stopPointX = 0;
      var resetPoint = function () {
        startPointX = 0;
        stopPointX = 0;
      };
      box.addEventListener("touchstart", function (e) {
        startPointX = e.changedTouches[0].pageX;
      });
      box.addEventListener("touchmove", function (e) {
        stopPointX = e.changedTouches[0].pageX;
      });
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint();
          return;
        }
        if (startPointX - stopPointX > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPointX - stopPointX < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },
  },
};
</script>

<style scoped>
.top {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  width: 100%;
}
.home {
  padding-top: 4.7rem;
}
.logo {
  height: 3.68rem;
  width: auto;
}
.lange {
  margin-right: 0.35rem;
}
h1,
h3 {
  margin: 0.08rem 0;
  text-align: center;
}
.main {
  padding: 0 0.8rem;
}
.main-img {
  width: 100%;
  height: 16rem;
}
.main-content-title {
  border-bottom: 1px dashed #9b9c9c;
  position: relative;
  margin-top: 2.8rem;
}
.main-content-title-one {
  border-bottom: 6px solid #29b0d7;
  display: inline-block;
  position: absolute;
  bottom: -3px;
  font-size: 1rem;
  color: #29b0d7;
  padding-bottom: 0.08rem;
  font-weight: 700;
}
.main-content-title-two {
  font-size: 0.5rem;
  color: #29b0d7;
  margin-top: -0.55rem;
  margin-bottom: 0.68rem;
}
.main-content {
  color: #333;
  line-height: 1.8;
  font-size: 1rem;
  padding-top: 0.8rem;
}
::v-deep .el-carousel__container {
  height: 16rem;
}
::v-deep .el-collapse-item__header,
::v-deep .el-collapse-item__content {
  font-size: 1rem !important;
}
.image {
  height: 10rem;
  width: 100%;
  cursor: pointer;
}
.card-name {
  color: #29b0d7;
  font-weight: 700;
}
.describe {
  color: #666;
  line-height: 1.5;
  margin-top: 0.5rem;
  min-height: 5.2rem;
}
.bottom {
  padding: 0.8rem;
  background-color: #474747;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
}
.goTop {
  position: fixed;
  background-color: #fff;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  color: #0552a0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 5;
  right: 1rem;
  bottom: 1rem;
}
.arabGoTop {
  right: none;
  left: 1rem;
}
a {
  color: #fff;
  text-decoration: none;
}

.language {
  font-size: 1rem;
  text-align: center;
  width: 100%;
  border-top: 1px dashed #9b9c9c;
  border-bottom: 1px dashed #9b9c9c;
  margin-top: 2rem;
  padding: 0.5rem 0;
}
.language span {
  margin: 0 0.5rem;
}
.language span:hover,
.language span.cur {
  color: #676767;
  font-weight: 700;
}
.el-icon-menu {
  font-size: 2rem;
  line-height: 1.5;
  margin-right: 1rem;
}
.menu-icon {
  width: 1.8rem;
  height: 0.2rem;
  border-top: 0.2rem solid #a3a4a9;
  border-bottom: 0.2rem solid #a3a4a9;
  background-color: #a3a4a9;
  padding: 0.35rem 0;
  background-clip: content-box;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.menu-main {
  background-color: rgb(205, 204, 204, 0.75);
  width: 100%;
  height: calc(100vh - 4.9rem);
  font-size: 1rem;
}
.line {
  width: 1.5rem;
  height: 0.1rem;
  background: #29b0d7;
}
.nav-1 {
  color: #29b0d7;
  margin-left: 0.5rem;
  font-weight: 700;
}
.nav-2 {
  font-weight: 700;
  padding-left: 2.8rem;
  line-height: 2.6;
  background-color: rgb(224, 224, 224);
  margin-top: 0.5rem;
}
.flex-start {
  padding: 0.8rem 1rem 0 1rem;
}
.lxwm {
  position: absolute;
  top: 6rem;
  left: 0;
  font-size: 1.8rem;
  color: #29b0d7;
  text-align: center;
  width: 100%;
  line-height: 2;
  font-weight: 700;
}
.lxwm-p {
  font-size: 1rem;
  line-height: 2.5;
  margin: 0.5rem;
}
.lxwm-i {
  margin-right: 10px;
  font-size: 1rem;
}
.lxwm-s {
  font-weight: 700;
}
.lxwm-i-p {
  color: #595959;
}
.lxwm-t {
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 3px;
}
.card-title {
  font-weight: 700;
  line-height: 1.5;
}
.kczy-title {
  position: absolute;
  top: 6rem;
  left: 0;
  font-size: 0.8rem;
  color: #29b0d7;
  text-align: center;
  width: 100%;
  line-height: 2;
  font-weight: 700;
}
.dialog-content {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
}
.h-5 {
  min-height: 6rem;
}
.h-3 {
  min-height: 3.2rem;
}
::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #b8cce4;
  color: black;
}
::v-deep .el-table th.el-table__cell {
  background: #4f81bd;
  color: black;
  font-weight: 700;
}
::v-deep table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  color: black;
}
::v-deep .el-table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
::v-deep .el-table td,
::v-deep .el-table th {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.drawer-content {
  padding: 0.5rem;
  width: 100%;
  margin: 0 auto;
}
::v-deep .el-drawer__header {
  font-weight: 700;
  font-size: 1.5rem;
  color: black;
}
</style>
