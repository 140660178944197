import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import "./assets/css/public.css"
import App from './App.vue'

//多语言文件
import en from './locale/en.json'
import zh from './locale/zh.json'
import arab from './locale/arab.json'

const messages = {
  en,
  zh,
  arab,
}

let i18nConfig = {
  locale: localStorage.getItem("language") || 'zh',// 获取已设置的语言（缓存的值）
  messages
}
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)
Vue.use(VueI18n)
const i18n = new VueI18n(i18nConfig)
Vue.config.productionTip = false
new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
